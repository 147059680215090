import React from 'react'
import { Link } from 'gatsby'

import './header.css'

interface Props {
}

const Header: React.FC<Props> = () => (
  <header className="header">
    
    {/* Color bar */}
    <div className="color-bar"></div>

    {/* Wrapper */}
    <div className="wrapper">

      <div className="inner">

        <h4>
        <Link to="/">
            MIT Press books by Volnei A. Pedroni
        </Link>
        </h4>

        {/* Menu */}
        <div className="menu">
          <Link to="/">Home</Link>
          <Link to="/previous-books">Previous Books</Link>
          <Link to="/for-fun">For fun</Link>
          <Link to="/about">About the Author</Link>
        </div>

      </div>

    </div>

  </header>
)

export default Header
