import React from 'react'

// Import semantic-ui-css here for general use
import 'semantic-ui-css/semantic.min.css'

import Header from './header'
import './layout.css'

interface Props {
  children: any
}

const Layout: React.FC<Props> = ({ children }) => {

  return (
    <>
      <Header />
      
      <div className="content">
        <main>{children}</main>        
      </div>
      
    </>
  )
}

export default Layout
